import {
  ERROR_AGE_GROUPS,
  ERROR_ILLNESSES,
  ERROR_SERVICES,
  SET_AGE_GROUPS,
  SET_CORE_SERVICES,
  SET_FILTER_TERM_AGGREGATIONS,
  SET_ILLNESSES,
  SET_SERVICES,
  SET_SUB_SERVICES,
  START_AGE_GROUPS,
  START_ILLNESSES,
  START_SERVICES,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  services: [],
  core_services: [],
  sub_services: [],
  fetchingServices: false,
  serviceError: null,
  age_groups: [],
  fetchingAgeGroups: false,
  ageGroupsError: null,
  illnesses: [],
  fetchingIllnesses: false,
  illnessesError: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case START_SERVICES: {
      return {
        ...state,
        fetchingServices: true,
      };
    }
    case SET_SERVICES: {
      return {
        ...state,
        services: action.payload,
        fetchingServices: false,
      };
    }
    case SET_CORE_SERVICES: {
      return {
        ...state,
        core_services: action.payload,
        fetchingServices: false,
      };
    }

    case SET_SUB_SERVICES: {
      return {
        ...state,
        sub_services: action.payload,
        fetchingServices: false,
      };
    }

    case ERROR_SERVICES: {
      return {
        ...state,
        serviceError: action.payload,
      };
    }

    case START_ILLNESSES: {
      return {
        ...state,
        fetchingIllnesses: true,
      };
    }

    case SET_ILLNESSES: {
      return {
        ...state,
        illnesses: action.payload,
        fetchingIllnesses: false,
      };
    }

    case ERROR_ILLNESSES: {
      return {
        ...state,
        illnessesError: action.payload,
      };
    }

    case START_AGE_GROUPS: {
      return {
        ...state,
        fetchingAgeGroups: true,
      };
    }
    case SET_AGE_GROUPS: {
      return {
        ...state,
        age_groups: action.payload,
        fetchingAgeGroups: false,
      };
    }
    case ERROR_AGE_GROUPS: {
      return {
        ...state,
        ageGroupsError: action.payload,
      };
    }
    case SET_FILTER_TERM_AGGREGATIONS: {
      return {
        ...state,
        services: action.servicesCheckboxes,
        // core_services: action.coreSerivcesCheckboxes,
        // sub_services: action.subServicesCheckboxes,
        age_groups: action.ageGroupsCheckboxes,
        illnesses: action.illnessesCheckboxes,
      };
    }
    default:
      return state;
  }
};
