import { combineReducers } from "redux";
import Common from "./Common";
import Fields from "./Fields";
import Filters from "./Filters";
import Search from "./Search";
import Checkboxes from "./CheckBoxes";
import AutoComplete from "./Autocomplete";

export default combineReducers({
  common: Common,
  fields: Fields,
  filters: Filters,
  search: Search,
  checkboxes: Checkboxes,
  autoComplete: AutoComplete,
});
