export const LANGUAGE_FR_CODE = "fr";
export const LANGUAGE_EN_CODE = "en";
export const ALL_TAB = "ALL_TAB";
export const GLOBAL_SEARCH_TAB = "GLOBAL_SEARCH_TAB";
export const FOR_PROFESSIONAL_TAB = "FOR_PROFESSIONAL_TAB";
export const PRACTICAL_ADVICE_TAB = "PRACTICAL_ADVICE_TAB";
export const TABS = [
    ALL_TAB,
    PRACTICAL_ADVICE_TAB,
    FOR_PROFESSIONAL_TAB,
];

export const GLOBAL_SEARCH_ELASTICSEARCH_DOCUMENT = "GeneralContent";
export const FOR_PROFESSIONAL_ELASTICSEARCH_DOCUMENT = "ForProfessionalContent";
export const PRACTICAL_ADVICE_ELASTICSEARCH_DOCUMENT = "PracticalAdvice";
export const GLOBAL_SEARCH_ELASTICSEARCH_INDEX = "general-content-index";
export const FOR_PROFESSIONAL_ELASTICSEARCH_INDEX = "for-professional-content-index";
export const PRACTICAL_ADVICE_ELASTICSEARCH_INDEX = "practical-advice-index";

export const TABS_TO_ES_DOCS = {
    [GLOBAL_SEARCH_ELASTICSEARCH_DOCUMENT]: ALL_TAB,
    [PRACTICAL_ADVICE_ELASTICSEARCH_DOCUMENT]: PRACTICAL_ADVICE_TAB,
    [FOR_PROFESSIONAL_ELASTICSEARCH_DOCUMENT]: FOR_PROFESSIONAL_TAB,
};
/**
 * Mapping each tab to it's elasticsearch document
 */
export const TAB_MAPPINGS = {
    ALL_TAB: {
        GeneralContent: GLOBAL_SEARCH_ELASTICSEARCH_DOCUMENT,
    },
    GLOBAL_SEARCH_TAB: {
        GeneralContent: GLOBAL_SEARCH_ELASTICSEARCH_DOCUMENT,
    },
    PRACTICAL_ADVICE_TAB: {
        PracticalAdvice: PRACTICAL_ADVICE_ELASTICSEARCH_DOCUMENT,
    },
    FOR_PROFESSIONAL_TAB: {
        ForProfessionalContent: FOR_PROFESSIONAL_ELASTICSEARCH_DOCUMENT,
    },
};

/**
 * Sort columns for each Tab
 */
export const DOCUMENT_SORTING_COLUMNS = {
    GeneralContent: "title",
    ForProfessionalContent: "title",
    PracticalAdvice: "title",
};

/**
 * mapping each tab to it's elasticsearch index
 */
export const TAB_INDEX_MAPPING = {
    [GLOBAL_SEARCH_TAB]: GLOBAL_SEARCH_ELASTICSEARCH_INDEX,
    [PRACTICAL_ADVICE_TAB]: PRACTICAL_ADVICE_ELASTICSEARCH_INDEX,
    [FOR_PROFESSIONAL_TAB]: FOR_PROFESSIONAL_ELASTICSEARCH_INDEX,
};

// ElasticSearch API operators
export const IS = "is";
export const IS_NOT = "is_not";
export const CONTAIN = "contain";
export const SMALLER = "smaller";
export const GREATER = "greater";
export const ISTARTSWITH = "istartswith";

// Available search operators
export const AND = "and";
export const OR = "or";

// Sorting
export const ORDER_BY_RELEVANCE = "ORDER_BY_RELEVANCE";
export const ORDER_ALPHABETICALLY = "ORDER_ALPHABETICALLY";

// Pagination page size
export const PAGE_SIZE = 10; // The page size need to be modified in the backend as well

// Result views Types
export const LIST_VIEW = "LIST_VIEW";
export const GRID_VIEW = "GRID_VIEW";
export const MAP_VIEW = "MAP_VIEW";

// Alert Type
export const SUCCESS = "success";
export const DANGER = "danger";
export const WARNING = "warning";

// Default resource filters
export const IS_PUBLISHED_FILTER = {
    condition: "and",
    field: "publish",
    operator: "is",
    value: true,
};


export const RECENT_SEARCHES_STORAGE = "recent_searches";
export const RESOURCE_ELASTICSEARCH_DOCUMENT = "Resource";
export const DOCUMENTS_ELASTICSEARCH_DOCUMENT = "Publication";
export const NEWS_ELASTICSEARCH_DOCUMENT = "ActualityArticlePage";

export const MAX_RECENT_SEARCHES_LENGTH = 3;
