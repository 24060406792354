// Common Actions
export const FETCH_START = "FETCH_START";
export const FETCH_SUCCESS = "FETCH_SUCCESS";
export const FETCH_ERROR = "FETCH_ERROR";
export const SET_CURRENT_LANGUAGE = "SET_CURRENT_LANGUAGE";

// Tabs
export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";

// Fields Actions
export const FETCH_NEWS_FIELDS = "FETCH_NEWS_FIELDS";
export const FETCH_DOCUMENT_FIELDS = "FETCH_DOCUMENT_FIELDS";
export const FETCH_GLOBAL_SEARCH_FIELDS = "FETCH_GLOBAL_SEARCH_FIELDS";
export const FETCH_RESOURCE_FIELDS = "FETCH_RESOURCE_FIELDS";

// Build elasticsearch queries
export const BUILD_ELASTIC_SEARCH_QUERY = "BUILD_ELASTIC_SEARCH_QUERY";

// Set filters values
export const SET_NEWS_FILTERS = "SET_NEWS_FILTERS";
export const SET_DOCUMENT_FILTERS = "SET_DOCUMENT_FILTERS";
export const SET_GLOBAL_SEARCH_FILTERS = "SET_GLOBAL_SEARCH_FILTERS";
export const SET_RESOURCE_FILTERS = "SET_RESOURCE_FILTERS";
export const SET_RESOURCE_LOCATION = "SET_RESOURCE_LOCATION";
export const RESET_RESOURCE_LOCATION = "RESET_RESOURCE_LOCATION";
export const SET_SEARCH_QUERY = "SET_SEARCH_QUERY";
export const SET_SORT_BY = "SET_SORT_BY";
export const SET_PAGE = "SET_PAGE";
export const SET_VIEW_TYPE = "SET_VIEW_TYPE";
export const RESET_FILTERS = "RESET_FILTERS";
export const SET_PAGE_SIZE = "SET_PAGE_SIZE";

// Search
export const START_SEARCH = "START_SEARCH";
export const SEARCH_SUCCESS = "SEARCH_SUCCESS";

// Checkboxes
export const START_SERVICES = "START_SERVICES";
export const SET_SERVICES = "SET_SERVICES";
export const SET_CORE_SERVICES = "SET_CORE_SERVICES";
export const SET_SUB_SERVICES = "SET_SUB_SERVICES";
export const ERROR_SERVICES = "ERROR_SERVICES";
export const START_AGE_GROUPS = "START_AGE_GROUPS";
export const SET_AGE_GROUPS = "SET_AGE_GROUPS";
export const ERROR_AGE_GROUPS = "ERROR_AGE_GROUPS";
export const START_ILLNESSES = "START_ILLNESSES";
export const SET_ILLNESSES = "SET_ILLNESSES";
export const ERROR_ILLNESSES = "ERROR_ILLNESSES";

// Aggregations
export const SET_FILTER_TERM_AGGREGATIONS = "SET_FILTER_TERM_AGGREGATIONS";

// Suggestions
export const TOGGLE_SUGGESTIONS_MODAL = "TOGGLE_SUGGESTIONS_MODAL";
export const SET_AUTOCOMPLETE_QUERY = "SET_AUTOCOMPLETE_QUERY";
export const START_AUTOCOMPLETE_SUGGESTIONS = "START_AUTOCOMPLETE_SUGGESTIONS";
export const SUCCESS_AUTOCOMPLETE_SUGGESTIONS =
  "SUCCESS_AUTOCOMPLETE_SUGGESTIONS";
export const ERROR_AUTOCOMPLETE_SUGGESTIONS = "ERROR_AUTOCOMPLETE_SUGGESTIONS";
export const SAVE_RECENT_SEARCHES = "SAVE_RECENT_SEARCHES";
export const DELETE_RECENT_SEARCH_ELEMENT = "DELETE_RECENT_SEARCH_ELEMENT";
