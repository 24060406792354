import {
  FETCH_DOCUMENT_FIELDS,
  FETCH_GLOBAL_SEARCH_FIELDS,
  FETCH_NEWS_FIELDS,
  FETCH_RESOURCE_FIELDS,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  newsFields: [],
  documentFields: [],
  globalSearchFields: [],
  loading: false,
  errors: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_NEWS_FIELDS: {
      return {
        ...state,
        newsFields: action.payload,
        errors: null,
      };
    }
    case FETCH_DOCUMENT_FIELDS: {
      return {
        ...state,
        documentFields: action.payload,
        errors: null,
      };
    }

    case FETCH_GLOBAL_SEARCH_FIELDS: {
      return {
        ...state,
        globalSearchFields: action.payload,
        errors: null,
      };
    }

    case FETCH_RESOURCE_FIELDS: {
      return {
        ...state,
        resourceFields: action.payload,
        errors: null,
      };
    }

    default:
      return state;
  }
};
