import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SET_CURRENT_LANGUAGE,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  error: "",
  loading: false,
  message: "",
  currentLanguage: "en",
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_START: {
      return {
        ...state,
        error: "",
        message: "",
        loading: true,
        fieldsErrors: {},
      };
    }

    case FETCH_SUCCESS: {
      return {
        ...state,
        error: "",
        message: "",
        loading: false,
        fieldsErrors: null,
      };
    }

    case FETCH_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        message: "",
        fieldsErrors: action.fieldsErrors,
      };
    }
    case SET_CURRENT_LANGUAGE: {
      return {
        ...state,
        currentLanguage: action.payload,
      };
    }

    default:
      return state;
  }
};
