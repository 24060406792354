import {
  SET_NEWS_FILTERS,
  SET_DOCUMENT_FILTERS,
  SET_GLOBAL_SEARCH_FILTERS,
  BUILD_ELASTIC_SEARCH_QUERY,
  SET_ACTIVE_TAB,
  SET_SEARCH_QUERY,
  SET_PAGE,
  SET_SORT_BY,
  SET_RESOURCE_FILTERS,
  RESET_FILTERS,
  SET_RESOURCE_LOCATION, RESET_RESOURCE_LOCATION, SET_PAGE_SIZE,
} from "../../constants/ActionTypes";
import {
  DOCUMENTS_ELASTICSEARCH_DOCUMENT,
  GLOBAL_SEARCH_ELASTICSEARCH_DOCUMENT,
  NEWS_ELASTICSEARCH_DOCUMENT,
  RESOURCE_ELASTICSEARCH_DOCUMENT,
  ORDER_BY_RELEVANCE,
  RESOURCE_TAB,
} from "../../constants/appConstants";
import { baseFilters, transformStateToPayload } from "../../utils/helpers";

const INIT_STATE = {
  filters: {
    [NEWS_ELASTICSEARCH_DOCUMENT]: {},
    [DOCUMENTS_ELASTICSEARCH_DOCUMENT]: {},
    [GLOBAL_SEARCH_ELASTICSEARCH_DOCUMENT]: {},
    [RESOURCE_ELASTICSEARCH_DOCUMENT]: {},
  },
  locations: {
    [RESOURCE_ELASTICSEARCH_DOCUMENT]: {},
  },
  payload: {
    query: "",
    page: 1,
    size: 10,
    filters: [...baseFilters],
  },
  searchQuery: "",
  page: 1,
  size: 10,
  activeTab: RESOURCE_TAB,
  sorting: ORDER_BY_RELEVANCE,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_NEWS_FILTERS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          [NEWS_ELASTICSEARCH_DOCUMENT]: {
            ...state.filters[NEWS_ELASTICSEARCH_DOCUMENT],
            [action.field]: {
              field: action.columnName,
              value: action.payload,
              operator: action.operator,
            },
          },
        },
      };
    }

    case SET_PAGE_SIZE: {
        return {
            ...state,
            size: action.payload,
        };
    }

    case SET_DOCUMENT_FILTERS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          [DOCUMENTS_ELASTICSEARCH_DOCUMENT]: {
            ...state.filters[DOCUMENTS_ELASTICSEARCH_DOCUMENT],
            [action.field]: {
              field: action.columnName,
              value: action.payload,
              operator: action.operator,
            },
          },
        },
      };
    }

    case SET_GLOBAL_SEARCH_FILTERS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          [GLOBAL_SEARCH_ELASTICSEARCH_DOCUMENT]: {
            ...state.filters[GLOBAL_SEARCH_ELASTICSEARCH_DOCUMENT],
            [action.field]: {
              field: action.columnName,
              value: action.payload,
              operator: action.operator,
            },
          },
        },
      };
    }

    case SET_RESOURCE_FILTERS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          [RESOURCE_ELASTICSEARCH_DOCUMENT]: {
            ...state.filters[RESOURCE_ELASTICSEARCH_DOCUMENT],
            [action.field]: {
              field: action.columnName,
              value: action.payload,
              operator: action.operator,
            },
          },
        },
      };
    }

    case SET_RESOURCE_LOCATION: {
      return {
        ...state,
        locations: {
          ...state.locations,
          [RESOURCE_ELASTICSEARCH_DOCUMENT]: {
            ...state.locations[RESOURCE_ELASTICSEARCH_DOCUMENT],
            ...action.field,
          },
        },
      };
    }
    case RESET_RESOURCE_LOCATION: {
      return {
        ...state,
        locations: {
          ...state.locations,
          [RESOURCE_ELASTICSEARCH_DOCUMENT]: {},
        },
      };
    }

    case SET_SORT_BY: {
      return {
        ...state,
        sorting: action.payload,
      };
    }

    case BUILD_ELASTIC_SEARCH_QUERY: {
      const payload = transformStateToPayload(
        state.filters,
        state.locations,
        state.activeTab,
        state.searchQuery,
        state.page,
        state.size,
        state.sorting,
        action.currentlanguage,
        action.defaultFilter
      );
      return {
        ...state,
        payload: payload,
      };
    }
    case SET_SEARCH_QUERY: {
      return {
        ...state,
        searchQuery: action.payload,
      };
    }
    case SET_ACTIVE_TAB: {
      return {
        ...state,
        activeTab: action.payload,
        page: 1,
      };
    }
    case SET_PAGE: {
      return {
        ...state,
        page: action.payload,
      };
    }

    case RESET_FILTERS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          [RESOURCE_ELASTICSEARCH_DOCUMENT]: {},
        },
      };
    }

    default:
      return state;
  }
};
