import {
  ERROR_AUTOCOMPLETE_SUGGESTIONS,
  SET_AUTOCOMPLETE_QUERY,
  SAVE_RECENT_SEARCHES,
  START_AUTOCOMPLETE_SUGGESTIONS,
  SUCCESS_AUTOCOMPLETE_SUGGESTIONS,
  TOGGLE_SUGGESTIONS_MODAL,
} from "../../constants/ActionTypes";
import axios from "../../services/api";
import {
  debounce,
  getCookie,
  setErrorMessage,
  setLocalStorage,
} from "../../utils/helpers";
import {
  MAX_RECENT_SEARCHES_LENGTH,
  RECENT_SEARCHES_STORAGE,
  RESOURCE_ELASTICSEARCH_DOCUMENT,
} from "../../constants/appConstants";

/**
 * Get our Django csrf token
 */
const csrftoken = getCookie("csrftoken");

/**
 * Get autocomplete suggestions
 *
 * @param {*} model - the ELASTICSEARCH index to search
 * @param {*} query - the query to search for
 * @param {*} callback - callback function to execute after the request
 * @returns
 */
export const getAutoCompleteSuggestions = (
  model = RESOURCE_ELASTICSEARCH_DOCUMENT,
  callback = () => {}
) => {
  return (dispatch, getState) => {
      const currentLanguage = window.languageCode;
    // console.log('currentLanguage', currentLanguage);
    axios.defaults.headers.common["X-CSRFToken"] = csrftoken;
    dispatch({ type: START_AUTOCOMPLETE_SUGGESTIONS });
    // const url = `/api/search/suggestions/?model=${model}&query=${
    //       getState().autoComplete.query
    //     }`;
    const url = `/${currentLanguage}/api/search/suggestions/?model=${model}&query=${
          getState().autoComplete.query
        }`;
    console.log('url', url);
    axios
      .get(url)
      .then(({ data }) => {
        dispatch({
          type: SUCCESS_AUTOCOMPLETE_SUGGESTIONS,
          payload: data.autocomplete,
        });
        callback();
      })
      .catch((error) => {
        dispatch(
          fetchAutoCompleteSuggestionsError({
            message: error.message,
            status: error.response.status,
          })
        );
      });
  };
};

/**
 * Dispatch this action on any failure of API call
 *
 * @param {*} error
 * @returns
 */
export const fetchAutoCompleteSuggestionsError = (error) => {
  const message = setErrorMessage(error.status, error.message);
  return {
    type: ERROR_AUTOCOMPLETE_SUGGESTIONS,
    payload: message,
  };
};

/**
 * Debounce for search query
 */
const debouncedStartSuggestions = debounce((dispatch, esIndex) => {
  dispatch(getAutoCompleteSuggestions(esIndex));
}, 600);

/**
 * Set autocomplete query
 *
 * @param {*} ESindex
 * @param {*} query
 * @returns
 */
export const setAutoCompleteQuery = (
  query,
  ESindex = RESOURCE_ELASTICSEARCH_DOCUMENT
) => {
  return (dispatch) => {
    dispatch({
      type: SET_AUTOCOMPLETE_QUERY,
      payload: query,
    });
    if (query && query.length > 0) {
      debouncedStartSuggestions(dispatch, ESindex);
    }
  };
};

/**
 * SAVE autocomplete suggestions to empty
 *
 * @returns
 */
export const setAutoCompleteSuggestionsToEmpty = () => {
  return (dispatch) => {
    dispatch({
      type: SUCCESS_AUTOCOMPLETE_SUGGESTIONS,
      payload: [],
    });
  };
};

/**
 * Save recent searches
 *
 * @param {*} query
 * @returns
 */
export const saveRecentSearches = (query) => {
  query = query.toLowerCase();
  return (dispatch, getState) => {
    const recentSearcheQueries = getState().autoComplete.recentSearcheQueries;
    if (!recentSearcheQueries.includes(query) && query.length > 0) {
      const updatedSearches = [query, ...recentSearcheQueries];
      if (updatedSearches.length > MAX_RECENT_SEARCHES_LENGTH) {
        updatedSearches.pop();
      }
      dispatch({
        type: SAVE_RECENT_SEARCHES,
        payload: updatedSearches,
      });
      setLocalStorage(RECENT_SEARCHES_STORAGE, updatedSearches);
    }
  };
};

/**
 * Toggle suggestions modal
 *
 * @param {*} status
 * @returns
 */
export const toggleSuggestionsModal = (status) => {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_SUGGESTIONS_MODAL,
      payload: status,
    });
  };
};

/**
 * Delete search history item
 *
 * @param {*} index
 * @returns
 */
export const deleteRecentSearchItem = (index) => {
  return (dispatch, getState) => {
    const recentSearcheQueries = getState().autoComplete.recentSearcheQueries;
    recentSearcheQueries.splice(index, 1);
    dispatch({
      type: SAVE_RECENT_SEARCHES,
      payload: recentSearcheQueries,
    });
    setLocalStorage(RECENT_SEARCHES_STORAGE, recentSearcheQueries);
  };
};
