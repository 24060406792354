import {
  ERROR_AUTOCOMPLETE_SUGGESTIONS,
  TOGGLE_SUGGESTIONS_MODAL,
  SAVE_RECENT_SEARCHES,
  SET_AUTOCOMPLETE_QUERY,
  START_AUTOCOMPLETE_SUGGESTIONS,
  SUCCESS_AUTOCOMPLETE_SUGGESTIONS,
} from "../../constants/ActionTypes";
import { RECENT_SEARCHES_STORAGE } from "../../constants/appConstants";
import { getLocalStorage } from "../../utils/helpers";

const INIT_STATE = {
  query: "",
  results: [],
  loading: false,
  error: false,
  errorMessage: "",
  recentSearcheQueries: getLocalStorage(RECENT_SEARCHES_STORAGE, []),
  suggestionModalOpen: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_AUTOCOMPLETE_QUERY: {
      return {
        ...state,
        query: action.payload,
      };
    }
    case START_AUTOCOMPLETE_SUGGESTIONS: {
      return {
        ...state,
        loading: true,
        results: [],
        error: false,
        errorMessage: "",
      };
    }
    case SUCCESS_AUTOCOMPLETE_SUGGESTIONS: {
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: "",
        results: action.payload,
      };
    }
    case ERROR_AUTOCOMPLETE_SUGGESTIONS: {
      return {
        ...state,
        results: [],
        loading: false,
        error: true,
        errorMessage: action.payload,
      };
    }
    case SAVE_RECENT_SEARCHES: {
      return {
        ...state,
        recentSearcheQueries: action.payload,
      };
    }
    case TOGGLE_SUGGESTIONS_MODAL: {
      return {
        ...state,
        suggestionModalOpen: action.payload,
      };
    }

    default:
      return state;
  }
};
