import { SEARCH_SUCCESS, SET_PAGE_SIZE, SET_VIEW_TYPE } from "../../constants/ActionTypes";
import axios from "../../services/api";
import { FETCH_START, FETCH_SUCCESS } from "../../constants/ActionTypes";
import { getCookie, setCookie } from "../../utils/helpers";
import { buildElasticSearchQuery } from "./Filters";
import { fetchError } from "./Common";
import { setFilterTermAggregation } from "./Checkboxes";
import { MAP_VIEW } from "../../../GlobalSearch/constants/appConstants";

/**
 * Get our Django csrf token
 */
const csrftoken = getCookie("csrftoken");

/**
 * Start search
 *
 * @param {*} actionType
 * @param {*} fieldDocument
 * @return {*}
 */
export const startSearch = (callback = () => {
}) => {
    return (dispatch, getState) => {
        const lang = getState().common.currentLanguage || "en";
        dispatch(buildElasticSearchQuery());
        dispatch({ type: FETCH_START });
        axios.defaults.headers.common["X-CSRFToken"] = csrftoken;
        axios
            .post(`/${ lang }/api/search/`, getState().filters.payload)
            .then(({ data }) => {
                dispatch({ type: FETCH_SUCCESS });
                //get first 4 results and store in cookie
                const similarOrganisations = data.results.slice(0, Math.min(data.results.length, 4)).map((item) => {
                    return item.result.id;
                });
                setCookie("similarOrganisations", JSON.stringify(similarOrganisations), 7);
                dispatch({
                    type: SEARCH_SUCCESS,
                    payload: data,
                });
                callback();
                dispatch(setFilterTermAggregation());
            })
            .catch((error) => {
                dispatch(
                    fetchError({ message: error.message, status: error.response.status })
                );
            });
    };
};

export const setResultViewtype = (viewType) => {
    return (dispatch) => {
        dispatch({
            type: SET_PAGE_SIZE,
            payload: viewType === MAP_VIEW ? 9999 : 10,
        });
        dispatch({
            type: SET_VIEW_TYPE,
            payload: viewType,
        });
        dispatch(buildElasticSearchQuery());
        dispatch(startSearch());
    };
};
