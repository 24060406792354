import { applyMiddleware, compose } from "redux";
import { legacy_createStore as createStore } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers/";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

/**
 * Setup our redux store for Organisation search
 *
 * @export
 * @param {*} [initialState={}]
 * @return {*}
 */
export default function configureStore(initialState = {}) {
  return createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(thunk))
  );
}

// const store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(thunk)));
// export default store;