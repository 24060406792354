import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
} from "../../constants/ActionTypes";
import { setErrorMessage } from "../../utils/helpers";

/**
 * Dispatch this actioin whenever you lunch new API call
 *
 * @returns
 */
export const fetchStart = () => {
  return {
    type: FETCH_START,
  };
};

/**
 * Dispatch this action after successful API call
 *
 * @returns
 */
export const fetchSuccess = () => {
  return {
    type: FETCH_SUCCESS,
  };
};

/**
 * Dispatch this action on any failure of API call
 *
 * @param {*} error
 * @returns
 */
export const fetchError = (error) => {
  const message = setErrorMessage(error.status, error.message);
  return {
    type: FETCH_ERROR,
    payload: message,
  };
};
